<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilMoney" />
          DS Chi Phí
        </span>
        <div class="d-inline-block float-right align-right">
          <CButton
            color="info"
            size="sm"
            class="mr-2"
            @click.prevent="
              (e) => {
                showFilter = !showFilter;
              }
            "
          >
            <CIcon v-if="showFilter" name="cil-chevron-top" />
            <CIcon v-else name="cil-chevron-bottom" />
          </CButton>

          <CButton
            color="success"
            size="sm"
            class="float-right"
            @click.prevent="
              (e) => {
                editing = true;
                agencyExpense = null;
              }
            "
          >
            <CIcon name="cil-plus" />
            Thêm Chi phí
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <filter-box
          v-if="showFilter"
          :agencies="agencies"
          @done="(e) => doFilter(e)"
        />
        <odata-table
          ref="lstAgencyExpense"
          url="odata/AgencyExpense"
          tableClass="table-striped"
          :filter="filter"
          :expand="
            `Agency($select=Name),Expense($select=Code,Name,ExpenseType,Unit)`
          "
          sortBy="ExpenseDate desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Agency: {
              field: 'Agency/Name',
              display: 'Cửa hàng',
              sortable: false,
              style: 'min-width: 120px',
            },
            ExpenseDate: {
              field: 'ExpenseDate',
              display: 'Ngày',
              sortable: true,
              style: 'min-width: 120px',
            },
            ExpenseName: {
              field: 'Expense/Name',
              display: 'Tên Chi phí',
              sortable: true,
              style: 'min-width: 120px',
            },
            UnitPrice: {
              field: 'UnitPrice',
              display: 'Đơn giá',
              sortable: true,
              style: 'min-width: 70px',
            },
            Qty: {
              field: 'Qty',
              display: 'SL',
              sortable: true,
              style: 'min-width: 70px',
            },
            Discount: {
              field: 'DiscountValue',
              display: 'C.khấu',
              sortable: true,
              style: 'min-width: 70px',
            },
            Amount: {
              field: 'Amount',
              display: 'Thành tiền',
              sortable: true,
              style: 'min-width: 100px',
            },
            ExpenseUnit: {
              field: 'Expense/Unit',
              display: 'Đơn vị',
              sortable: true,
              style: 'min-width: 70px',
            },
            ExpenseType: {
              field: 'Expense/ExpenseType',
              display: 'Loại phí',
              sortable: true,
              style: 'min-width: 70px',
            },
            UpdatedAt: {
              field: 'UpdatedAt',
              display: 'Cập nhật',
              sortable: true,
              style: 'min-width: 120px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-if="$refs.lstAgencyExpense.loading">
              <td colspan="100%">
                <div class="spinner-border m-5" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>
                {{ i + 1 + ($refs.lstAgencyExpense.page - 1) * pageSize }}.
              </td>
              <td>
                {{ row.Agency.Name }}
              </td>
              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="
                    `Cập nhật Chi phí ngày: ${$moment
                      .utc(row.ExpenseDate)
                      .local()
                      .format('DD/MM/YYYY')}`
                  "
                  @click="
                    (e) => {
                      agencyExpense = row;
                      editing = true;
                    }
                  "
                >
                  <CIcon name="cil-pencil" />
                  {{
                    $moment
                      .utc(row.ExpenseDate)
                      .local()
                      .format("DD/MM/YYYY")
                  }}
                </a>
              </td>
              <td>{{ row.Expense.Code }} - {{ row.Expense.Name }}</td>
              <td class="text-right">
                {{ $func.addCommas(row.UnitPrice) }}
              </td>
              <td class="text-right">
                {{ $func.addCommas(row.Qty) }}
              </td>
              <td class="text-right">
                {{ $func.addCommas(row.DiscountValue)
                }}{{
                  row.DiscountType == $const.DISCOUNT_TYPES.Percent ? "%" : ""
                }}
              </td>
              <td class="text-right">
                <strong>{{ $func.addCommas(row.Amount) }}</strong>
              </td>
              <td>
                {{ row.Expense.Unit }}
              </td>
              <td>
                {{ $const.EXPENSE_TYPES_TEXT[row.Expense.ExpenseType] }}
              </td>
              <td>
                {{
                  $moment
                    .utc(row.UpdatedAt)
                    .local()
                    .format("DD/MM/YYYY HH:mm")
                }}
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :agencyExpense.sync="agencyExpense"
      :agencies="agencies"
      @reload="$refs.lstAgencyExpense.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";
import filterBox from "./Filter.vue";

export default {
  name: "AgencyExpense",
  components: {
    odataTable,
    detail,
    filterBox,
  },
  data() {
    return {
      pageSize: 10,
      agencyExpense: null,
      editing: false,
      showFilter: false,
      filters: null,
      agencies: [],
    };
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }
      return filters.join(" and ");
    },
  },
  async mounted() {
    this.agencies = await this.loadAgencies();
  },
  methods: {
    async loadAgencies() {
      let agencies = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Agency", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name,Status",
        },
      });
      if (resp && resp.status == 200) {
        agencies = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
            Status: _.Status,
          };
        });
      }
      return agencies;
    },

    doFilter(e) {
      if (JSON.stringify(this.filters) != JSON.stringify(e)) {
        this.filters = e;
      } else {
        this.$refs.lstAgencyExpense.loadData();
      }
    },
  },
};
</script>
