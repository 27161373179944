var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilMoney"}}),_vm._v(" DS Chi Phí ")],1),_c('div',{staticClass:"d-inline-block float-right align-right"},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
              _vm.showFilter = !_vm.showFilter;
            })($event)}}},[(_vm.showFilter)?_c('CIcon',{attrs:{"name":"cil-chevron-top"}}):_c('CIcon',{attrs:{"name":"cil-chevron-bottom"}})],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
              _vm.editing = true;
              _vm.agencyExpense = null;
            })($event)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm Chi phí ")],1)],1)]),_c('CCardBody',[(_vm.showFilter)?_c('filter-box',{attrs:{"agencies":_vm.agencies},on:{"done":function (e) { return _vm.doFilter(e); }}}):_vm._e(),_c('odata-table',{ref:"lstAgencyExpense",attrs:{"url":"odata/AgencyExpense","tableClass":"table-striped","filter":_vm.filter,"expand":"Agency($select=Name),Expense($select=Code,Name,ExpenseType,Unit)","sortBy":"ExpenseDate desc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          Agency: {
            field: 'Agency/Name',
            display: 'Cửa hàng',
            sortable: false,
            style: 'min-width: 120px',
          },
          ExpenseDate: {
            field: 'ExpenseDate',
            display: 'Ngày',
            sortable: true,
            style: 'min-width: 120px',
          },
          ExpenseName: {
            field: 'Expense/Name',
            display: 'Tên Chi phí',
            sortable: true,
            style: 'min-width: 120px',
          },
          UnitPrice: {
            field: 'UnitPrice',
            display: 'Đơn giá',
            sortable: true,
            style: 'min-width: 70px',
          },
          Qty: {
            field: 'Qty',
            display: 'SL',
            sortable: true,
            style: 'min-width: 70px',
          },
          Discount: {
            field: 'DiscountValue',
            display: 'C.khấu',
            sortable: true,
            style: 'min-width: 70px',
          },
          Amount: {
            field: 'Amount',
            display: 'Thành tiền',
            sortable: true,
            style: 'min-width: 100px',
          },
          ExpenseUnit: {
            field: 'Expense/Unit',
            display: 'Đơn vị',
            sortable: true,
            style: 'min-width: 70px',
          },
          ExpenseType: {
            field: 'Expense/ExpenseType',
            display: 'Loại phí',
            sortable: true,
            style: 'min-width: 70px',
          },
          UpdatedAt: {
            field: 'UpdatedAt',
            display: 'Cập nhật',
            sortable: true,
            style: 'min-width: 120px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return [(_vm.$refs.lstAgencyExpense.loading)?_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_c('div',{staticClass:"spinner-border m-5",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])]):_vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(" "+_vm._s(i + 1 + (_vm.$refs.lstAgencyExpense.page - 1) * _vm.pageSize)+". ")]),_c('td',[_vm._v(" "+_vm._s(row.Agency.Name)+" ")]),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật Chi phí ngày: " + (_vm.$moment
                    .utc(row.ExpenseDate)
                    .local()
                    .format('DD/MM/YYYY')))},on:{"click":function (e) {
                    _vm.agencyExpense = row;
                    _vm.editing = true;
                  }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" "+_vm._s(_vm.$moment .utc(row.ExpenseDate) .local() .format("DD/MM/YYYY"))+" ")],1)]),_c('td',[_vm._v(_vm._s(row.Expense.Code)+" - "+_vm._s(row.Expense.Name))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(row.UnitPrice))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(row.Qty))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(row.DiscountValue))+_vm._s(row.DiscountType == _vm.$const.DISCOUNT_TYPES.Percent ? "%" : "")+" ")]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$func.addCommas(row.Amount)))])]),_c('td',[_vm._v(" "+_vm._s(row.Expense.Unit)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$const.EXPENSE_TYPES_TEXT[row.Expense.ExpenseType])+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.UpdatedAt) .local() .format("DD/MM/YYYY HH:mm"))+" ")])])})]}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"agencyExpense":_vm.agencyExpense,"agencies":_vm.agencies},on:{"update:editing":function($event){_vm.editing=$event},"update:agencyExpense":function($event){_vm.agencyExpense=$event},"update:agency-expense":function($event){_vm.agencyExpense=$event},"reload":function($event){return _vm.$refs.lstAgencyExpense.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }