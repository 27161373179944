<template>
  <div>
    <div class="form-row">
      <CCol md="3">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-4 text-right">Cửa hàng</label>
            <v-select
              v-model="agencyId"
              :options="
                agencies.filter((_) => _.Status == $const.STATUS.Active)
              "
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Cửa hàng"
              class="col-8"
            />
          </div>
        </div>
      </CCol>
      <CCol md="2">
        <div role="group" class="form-group form-row">
          <label class="col-4 text-right col-form-label">
            Từ ngày
          </label>
          <div class="col-8">
            <datetime
              type="date"
              v-model="createdFrom"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="2">
        <div role="group" class="form-group form-row">
          <label class="col-4 text-right col-form-label">
            Đến
          </label>
          <div class="col-8">
            <datetime
              type="date"
              v-model="createdTo"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="3">
        <CInput
          label="Tên Chi Phí"
          placeholder="Tên Chi Phí"
          v-model="expenseName"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
          @keyup.enter="filter"
        />
      </CCol>
      <CCol md="2" class="text-center">
        <CButton color="primary" class="mr-2 mb-3" @click="filter">
          <CIcon name="cil-search" custom-classes="c-icon m-0" />
          Tìm kiếm
        </CButton>

        <CButton color="secondary" class="mb-3" @click="clear">
          <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
          Đặt lại
        </CButton>
      </CCol>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  props: ["agencies"],
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      createdFrom: null,
      createdTo: null,
      agencyId: null,
      expenseName: null,
    };
  },
  methods: {
    filter() {
      let filters = [];
      if (this.createdFrom) {
        let createdFrom = this.$moment(this.createdFrom).toISOString();
        filters.push(`ExpenseDate ge ${createdFrom}`);
      }
      if (this.createdTo) {
        let createdTo = this.$moment(this.createdTo)
          .add(1, "days")
          .toISOString();
        filters.push(`ExpenseDate lt ${createdTo}`);
      }
      this.agencyId && filters.push(`AgencyId eq ${this.agencyId}`);
      this.expenseName &&
        filters.push(`contains(/Name, '${this.expenseName}')`);

      this.$emit("done", filters);
    },

    clear() {
      this.createdFrom = null;
      this.createdTo = null;
      this.agencyId = null;
      this.expenseName = null;
    },
  },
};
</script>
