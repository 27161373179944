<template>
  <CModal
    :title="'Cập nhật Chi Phí Cửa hàng'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="xl"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto py-2"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <div class="form-row align-items-center">
          <div class="col-md-4">
            <div
              class="table-responsive overflow-auto"
              style="height: calc(100vh - 13rem)"
            >
              <table class="table table-sm table-hover table-striped">
                <thead>
                  <tr>
                    <th>
                      Chọn
                    </th>
                    <th>Tên chi phí</th>
                    <th style="min-width: 70px;">ĐV</th>
                    <!-- <th style="min-width: 70px;">Loại phí</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Lọc:
                    </td>
                    <td>
                      <input
                        v-model="expenseNameFilter"
                        type="text"
                        class="form-control form-control-sm"
                      />
                    </td>
                    <td>
                      <button
                        class="btn btn-sm btn-warning"
                        @click="clearFilter"
                        :disabled="!expenseNameFilter"
                      >
                        Xóa lọc
                      </button>
                    </td>
                  </tr>
                  <tr
                    v-for="(expense, i) in filteredExpenses"
                    :key="`expense-${i}`"
                  >
                    <td class="position-relative">
                      <CSwitch
                        style="top: 5px;"
                        class="position-absolute"
                        color="info"
                        size="sm"
                        variant="opposite"
                        shape="pill"
                        :checked.sync="expense.selected"
                      />
                    </td>
                    <td :class="{ 'font-weight-bold': expense.selected }">
                      {{ expense.Code }} - {{ expense.Name }}
                    </td>
                    <td>{{ expense.Unit }}</td>
                    <!-- <td>
                      {{ $const.EXPENSE_TYPES_TEXT[expense.ExpenseType] }}
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-1 text-center">
            <button
              :disabled="!expenses.some((_) => _.selected) || !agencyId"
              type="button"
              class="btn btn-sm btn-success my-1"
              @click="addToAgencyExpense"
            >
              <CIcon name="cilArrowCircleRight" />
              Thêm
            </button>
            <button
              :disabled="!agencyExpenses.some((_) => _.selected) || !agencyId"
              type="button"
              class="btn btn-sm btn-warning my-1"
              @click="removeFromAgencyExpense"
            >
              <CIcon name="cilArrowCircleLeft" />
              Xóa
            </button>
          </div>
          <div class="col-md-7">
            <div class="row">
              <CCol md="7">
                <div class="form-group">
                  <div class="form-row">
                    <label class="col-form-label col-4 text-right">
                      Chọn cửa hàng*:
                    </label>
                    <v-select
                      v-model="agencyId"
                      :options="
                        agencies.filter((_) => _.Status == $const.STATUS.Active)
                      "
                      :reduce="(p) => p.Id"
                      label="Name"
                      placeholder="Cửa hàng"
                      class="col-8"
                      :class="{
                        'is-invalid border-danger rounded': !!inValidObject[
                          'agencyId'
                        ],
                        disabled: agencyExpenses && agencyExpenses.length,
                      }"
                    />
                    <div class="invalid-feedback">
                      {{ inValidObject["agencyId"] }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol md="5">
                <CInput
                  v-if="agencyExpenses && agencyExpenses.length"
                  label="Ngày*:"
                  :value="$moment(expenseDate).format('DD/MM/yyyy')"
                  :disabled="true"
                  :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
                />
                <div v-else role="group" class="form-row form-group">
                  <label class="col-form-label col-4 text-right">
                    Ngày*:
                  </label>
                  <div class="col-8">
                    <datetime
                      type="date"
                      v-model="expenseDate"
                      :max-datetime="
                        $moment()
                          .endOf('day')
                          .toISOString()
                      "
                      :min-datetime="
                        $moment()
                          .startOf('month')
                          .toISOString()
                      "
                      format="dd/MM/yyyy"
                      :input-class="{
                        'form-control': true,
                        'is-invalid': !!inValidObject['expenseDate'],
                      }"
                      :class="{
                        'is-invalid': !!inValidObject['expenseDate'],
                      }"
                    />
                    <div class="invalid-feedback">
                      {{ inValidObject["expenseDate"] }}
                    </div>
                  </div>
                </div>
              </CCol>
            </div>

            <div
              class="table-responsive overflow-auto"
              style="height: calc(100vh - 16rem)"
            >
              <table class="table table-sm table-hover table-striped">
                <thead>
                  <tr>
                    <th>
                      Chọn
                    </th>
                    <th>Tên chi phí</th>
                    <th>Đơn giá</th>
                    <th>SL</th>
                    <th>C.Khấu</th>
                    <th style="min-width: 90px;">Thành tiền</th>
                    <!-- <th style="min-width: 70px;">Loại phí</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in filteredAgencyExpenses"
                    :key="`agencyExpense-${i}`"
                  >
                    <td class="position-relative">
                      <CSwitch
                        style="top: 5px;"
                        class="position-absolute"
                        color="info"
                        size="sm"
                        variant="opposite"
                        shape="pill"
                        :checked.sync="item.selected"
                      />
                    </td>
                    <td
                      :class="{
                        'font-weight-bold': item.selected,
                      }"
                    >
                      {{ item.ExpenseCode }} - {{ item.ExpenseName }}
                    </td>
                    <td>
                      <money
                        placeholder="Đơn giá"
                        v-model="item.UnitPrice"
                        class="form-control form-control-sm text-right"
                        suffix=""
                        :class="{
                          'is-invalid': !item.UnitPrice || item.UnitPrice < 0,
                        }"
                        style="width: 85px;"
                      />
                    </td>
                    <td>
                      <money
                        placeholder="Số lượng"
                        v-model="item.Qty"
                        :suffix="
                          ' ' + (item.ExpenseUnit ? item.ExpenseUnit : '')
                        "
                        class="form-control form-control-sm text-right"
                        :class="{
                          'is-invalid': !item.Qty || item.Qty < 0,
                        }"
                        style="width: 70px;"
                      />
                    </td>
                    <td>
                      <money
                        placeholder="Chiết khấu"
                        v-model="item.DiscountValue"
                        :suffix="
                          item.DiscountType == $const.DISCOUNT_TYPES.Percent
                            ? ' %'
                            : ''
                        "
                        class="form-control form-control-sm text-right"
                        :class="{
                          'is-invalid':
                            item.DiscountValue && item.DiscountValue < 0,
                        }"
                        style="width: 50px;"
                      />
                    </td>
                    <td class="text-right">
                      {{ $func.addCommas(amountCalc(item)) }}
                    </td>
                    <!-- <td>
                      {{ $const.EXPENSE_TYPES_TEXT[item.ExpenseType] }}
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <CButton
        class="btn btn-primary"
        :disabled="
          saving ||
            agencyExpenses.some((_) => !amountCalc(_) || amountCalc(_) < 0)
        "
        @click="saveInfo"
      >
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  components: { Datetime },
  props: ["editing", "agencyExpense", "agencies"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      expenses: [],
      agencyExpenses: [],
      saving: false,
      expenseNameFilter: "",
      agencyId: null,
      expenseDate: new Date().toISOString(),
    };
  },
  async mounted() {
    this.expenseNameFilter = "";
    await this.loadExpenses();
  },
  computed: {
    filteredExpenses() {
      let expenses = this.expenses;
      let agencyExpenses = this.agencyExpenses;
      let expenseNameFilter = this.expenseNameFilter;

      return expenses.filter((expense) => {
        return (
          !agencyExpenses.some(
            (_) => _.ExpenseId == expense.Id && !_.isDeleted
          ) &&
          this.$func
            .toNonAccentVietnamese(expense.Name)
            .includes(this.$func.toNonAccentVietnamese(expenseNameFilter))
        );
      });
    },

    filteredAgencyExpenses() {
      let agencyExpenses = this.agencyExpenses;

      return agencyExpenses.filter((_) => !_.isDeleted);
    },
  },
  watch: {
    // async agencyExpense(val) {
    //   debugger;
    //   let expenseDate = val ? val.ExpenseDate : new Date().toISOString();
    //   let agencyId = val ? val.AgencyId : 0;
    //   this.expenseDate = expenseDate;
    //   this.agencyId = agencyId;
    //   await this.loadAgencyExpenses(agencyId, expenseDate);
    // },
    async agencyId(val) {
      await this.loadAgencyExpenses(val, this.expenseDate);
    },
    async expenseDate(val) {
      await this.loadAgencyExpenses(this.agencyId, val);
    },
    async editing(val) {
      this.detailModal = val;
      if (val) {
        let agencyExpense = this.agencyExpense;
        let expenseDate = agencyExpense
          ? agencyExpense.ExpenseDate
          : new Date().toISOString();
        let agencyId = agencyExpense ? agencyExpense.AgencyId : 0;
        this.expenseDate = expenseDate;
        this.agencyId = agencyId;
        await this.loadAgencyExpenses(agencyId, expenseDate);
      } else {
        this.$emit("update:agencyExpense", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },

  methods: {
    async loadExpenses() {
      let result = [];
      let resp = await this.$http.get(`odata/Expense`, {
        params: {
          $filter: `Status eq ${this.$const.STATUS.Active}`,
          $select: "Id,Code,Name,ExpenseType,Unit",
          $orderby: "Code asc",
        },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          _.selected = false;
          return _;
        });
      }
      this.expenses = result;
    },
    async loadAgencyExpenses(agencyId, expenseDate) {
      let result = [];
      if (agencyId && expenseDate) {
        let filters = [];
        filters.push(`AgencyId eq ${agencyId}`);
        filters.push(
          `ExpenseDate ge ${this.$moment(expenseDate)
            .startOf("day")
            .toISOString()}`
        );
        filters.push(
          `ExpenseDate le ${this.$moment(expenseDate)
            .endOf("days")
            .toISOString()}`
        );

        let resp = await this.$http.get(`odata/AgencyExpense`, {
          params: {
            $filter: filters.join(` and `),
            $select: `Id,ExpenseId,UnitPrice,Qty,DiscountType,DiscountValue,Amount`,
            $expand: `Expense($select=Code,Name,ExpenseType,Unit)`,
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data.value.map((_) => {
            return {
              Id: _.Id,
              ExpenseId: _.ExpenseId,
              ExpenseCode: _.Expense.Code,
              ExpenseName: _.Expense.Name,
              ExpenseType: _.Expense.ExpenseType,
              ExpenseUnit: _.Expense.Unit,
              UnitPrice: _.UnitPrice,
              Qty: _.Qty,
              DiscountType: _.DiscountType,
              DiscountValue: _.DiscountValue,
              Amount: _.Amount,
              isDeleted: false,
            };
          });
        }
      }

      this.agencyExpenses = result;
    },
    clearFilter() {
      this.expenseNameFilter = "";
    },

    addToAgencyExpense() {
      let agencyExpenses = this.agencyExpenses;
      this.expenses.forEach((expense) => {
        if (expense.selected) {
          let agencyExpense = agencyExpenses.find(
            (_) => _.ExpenseId == expense.Id
          );
          if (agencyExpense) {
            if (agencyExpense.isDeleted) {
              agencyExpense.isDeleted = false;
            }
            agencyExpense.Amount = 0;
          } else {
            agencyExpenses.push({
              Id: 0,
              ExpenseId: expense.Id,
              ExpenseCode: expense.Code,
              ExpenseName: expense.Name,
              ExpenseType: expense.ExpenseType,
              ExpenseUnit: expense.Unit,
              UnitPrice: 0,
              Qty: 1,
              DiscountType: this.$const.DISCOUNT_TYPES.Percent,
              DiscountValue: 0,
              Amount: 0,
              isDeleted: false,
            });
          }
          expense.selected = false;
        }
      });
    },

    removeFromAgencyExpense() {
      this.agencyExpenses.forEach((item) => {
        if (item.selected) {
          item.isDeleted = true;
          item.selected = false;
        }
      });
      if (this.agencyExpenses.some((_) => _.isDeleted && !_.Id)) {
        this.agencyExpenses = this.agencyExpenses.filter(
          (_) => _.Id || !_.isDeleted
        );
      }
    },

    async saveInfo() {
      let agencyId = this.agencyId;
      let expenseDate = this.expenseDate;
      this.inValidObject = this.checkValid(agencyId, expenseDate);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let agencyExpenses = this.agencyExpenses;
        await this.$func.asyncForEach(agencyExpenses, async (item) => {
          if (item.Id) {
            if (item.isDeleted) {
              // delete
              await this.$http.delete(`odata/AgencyExpense/${item.Id}`);
            } else {
              // patch
              await this.$http.patch(`odata/AgencyExpense/${item.Id}`, {
                UnitPrice: item.UnitPrice,
                Qty: item.Qty,
                DiscountType: item.DiscountType,
                DiscountValue: item.DiscountValue,
                Amount: this.amountCalc(item),
              });
            }
          } else {
            // post
            await this.$http.post(`odata/AgencyExpense`, {
              ExpenseDate: expenseDate,
              ExpenseId: item.ExpenseId,
              AgencyId: agencyId,
              UnitPrice: item.UnitPrice,
              Qty: item.Qty,
              DiscountType: item.DiscountType,
              DiscountValue: item.DiscountValue,
              Amount: this.amountCalc(item),
            });
          }
        });
        alert("Cập nhật thành công!");
        this.$emit("reload");
        this.detailModal = false;
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(agencyId, expenseDate) {
      let inValidObject = {};

      if (!agencyId) inValidObject["agencyId"] = "Vui lòng chọn Cửa hàng!";
      if (!expenseDate) inValidObject["expenseDate"] = "Vui lòng chọn ngày!";

      return inValidObject;
    },

    amountCalc(item) {
      let subTotal = item.UnitPrice * item.Qty;
      return item.DiscountType == this.$const.DISCOUNT_TYPES.Percent
        ? subTotal * (1 - item.DiscountValue / 100)
        : subTotal - item.DiscountValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>
